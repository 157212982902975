/**
 * Copyright ©2022 Drivepoint
 */

import {Config} from "@bainbridge-growth/node-frontend";
import State from "@services/state/State";
import Jimo from "@services/telemetry/Jimo";
import DrivepointUser from "@services/user/DrivepointUser";
import Appcues from "./Appcues";
import FullStory from "./FullStory";
import ITelemetryProvider from "./ITelemetryProvider";
import Segment from "./Segment";

/**
 * This is where we embed third-party site analysis products. We do this here to keep index.html less cluttered, and
 * allow for conditional loading of a product if needed.
 */

export default class Telemetry {

  private static _user: DrivepointUser;
  private static _company: any;
  private static _company_config?: Record<string, any>;

  static providers: ITelemetryProvider[] = [
    new Segment(),
    new Appcues(),
    new FullStory(),
    new Jimo()
  ];

  static async init() {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { await provider.embed(); }
    }
    State.registerMany("user", "company", "data_config", (event: any) => {
      switch (event.key) {
        case "user":
          Telemetry.user = event.value;
          break;
        case "company":
          Telemetry.company = event.value;
          break;
        case "data_config":
          const dataProcessing = event.value?.data_processing;
          const amazonIsConnected = this.#isDataSourceConnected(dataProcessing?.AMAZON_ECOMMERCE_AIRBYTE_CONNECTION_ID);
          const wholesaleIsConnected = this.#isDataSourceConnected(dataProcessing?.WHOLESALE_AIRBYTE_CONNECTION_ID);
          const financeIsConnected = this.#isDataSourceConnected(dataProcessing?.FINANCE_AIRBYTE_CONNECTION_ID);
          const shopifyIsConnected = this.#isDataSourceConnected(dataProcessing?.SHOPIFY_AIRBYTE_CONNECTION_ID);

          Telemetry.company_config = {
            tier: dataProcessing?.DBT_DASHBOARD_VARS?.customer_tier,
            isDataSourcesConnected: amazonIsConnected || wholesaleIsConnected || financeIsConnected || shopifyIsConnected
          };
          break;
      }
      Telemetry.identify();
    });
  }

  static async identify() {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.identify(); }
    }
  }

  static async track(event: string, data: any = {}): Promise<void> {
    for (const provider of Telemetry.providers) {
      if (Config.get(`${provider.name}.enabled`)) { provider.track(event, data); }
    }
  }

  static set user(value: DrivepointUser) {
    this._user = value;
  }

  static set company(value: any) {
    this._company = value;
  }

  static set company_config(value: any) {
    this._company_config = value;
  }

  static get user(): DrivepointUser {
    return this._user;
  }

  static get company(): any {
    return this._company;
  }

  static get company_config(): any {
    return this._company_config;
  }

  static #isDataSourceConnected(dataSourceConnections: Record<string, any> | null): boolean {
    if (!dataSourceConnections) { return false; }
    return Object.keys(dataSourceConnections).filter(key => dataSourceConnections[key]?.status === "active").length > 0;
  }

}
