import {Config} from "@bainbridge-growth/node-frontend";
import ITelemetryProvider from "@services/telemetry/ITelemetryProvider";
import Telemetry from "@services/telemetry/Telemetry";

declare global {
  interface Window {
    jimo:
    | {
      push: (args: [string, string, (string | number | object)[]]) => void;
    }
    | Array<[string, string, (string | number | object)[]]>;
    jimoInit: Function;
    jimoKill: Function;
    JIMO_PROJECT_ID: string;
    JIMO_MANUAL_INIT: boolean;
    JIMO_SESSION_TOKEN: string;
    JIMO_CHANGELOG_PRELOAD: boolean;
    JIMO_DEFAULT_TRIGGER_HIDDEN: boolean;
  }
}

export default class Jimo implements ITelemetryProvider {

  get name(): string { return "jimo"; }

  async embed(): Promise<void> {
    if (window.jimo != null) {
      return;
    }
    window.jimo = [];

    const s = document.createElement("script");

    s.type = "text/javascript";
    s.async = true;
    s.src = "https://undercity.usejimo.com/jimo-invader.js";
    window["JIMO_PROJECT_ID"] = Config.get("jimo.id");
    document.getElementsByTagName("head")[0].appendChild(s);
  }

  async identify(): Promise<void> {
    if (!Telemetry.user || !Telemetry.company || !Telemetry.company_config) { return; }
    window["jimo"].push(["do", "identify", [Telemetry.user.excelUser.email]]);
    window.jimo.push([ "set", "user:name", [`${Telemetry.user.excelUser.firstName} ${Telemetry.user.excelUser.lastName}`] ]);
    window.jimo.push([ "set", "user:attributes", [{
      environment: Config.get("environment"),
      role: Telemetry.user.excelUser.role,
      companyId: Telemetry.company.id,
      companyName: Telemetry.company.name,
      isCompanyProspect: Telemetry.company_config.tier === "prospect",
      isDataSourcesConnected: Telemetry.company_config.isDataSourcesConnected,
      isFirstUser: Telemetry.company.excelUsersIds.findIndex((id: string) => id === Telemetry.user.excelUser.email) === 0
    }]]);
  }

  async track(event: string, data: any = {}): Promise<void> {}

}
